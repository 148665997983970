import React from 'react';
import { Grid } from '@mui/material';
import { GridForm } from 'components/Ui/styled';
import { HeadingMuiTypography } from 'components/Ui/styled';
import { FormInputDate } from 'components/Common/Forms/FormInputDate';
import { FormInputText } from 'components/Common/Forms/FormTextField';

const GeneralInfoForm = (props) => {
  const { control } = props;
  return (
    <>
      <HeadingMuiTypography variant="h6">General Info</HeadingMuiTypography>
      <GridForm container spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormInputText
            name="firstName"
            type="text"
            control={control}
            label="First Name"
            testId="generalInfoFN"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText
            name="lastName"
            type="text"
            control={control}
            label="Last Name"
            testId="generalInfoLN"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInputText
            name="workEmail"
            type="text"
            control={control}
            label="Work Email"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInputText
            name="phoneNumber"
            type="text"
            control={control}
            label="Phone Number"
            testId="generalInfoPN"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInputText
            name="homeAddress"
            type="text"
            control={control}
            label="Home Address"
            testId="generalInfoHA"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDate
            name="dateOfBirth"
            type="text"
            control={control}
            label="Date Of Birth"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText
            name="vontageExt"
            type="text"
            control={control}
            label="Vontage Ext"
            testId="generalInfoVE"
          />
        </Grid>
      </GridForm>
    </>
  );
};

export default GeneralInfoForm;
