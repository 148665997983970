import React, { useState } from 'react';
import { useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';
import TaskDialog from '../tasks/TaskDialog';
import { intialPageSize, taskComplete } from 'constants';
import { getTaskListData, taskColumns } from '../tasks/TasksDataGrid';
import { getTaskForm } from 'layouts/taskManagement/store/taskSlice/taskSlice';
import {
  getDataGridHeading,
  useResetSnackbar,
  useNotification,
  onSave,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import {
  getTask,
  getAllOverdueTasks,
  postMarkInComplete,
  postMarkComplete,
} from 'layouts/taskManagement/services/taskManagementApi';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const AllOverdueTaskList = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taskCompletedLoading, setTaskCompletedLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState(null);

  const [allOverdueTaskList, setAllOverdueTaskList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchAllOverdueTasks();
  }, []);

  const fetchAllOverdueTasks = async () => {
    setAllOverdueTaskList({ ...allOverdueTaskList, isLoading: true });
    try {
      const allOverdueTasks = await getAllOverdueTasks();
      setAllOverdueTaskList({ ...allOverdueTaskList, isLoading: false });
      setAllOverdueTaskList((prev) => ({
        ...prev,
        rows: getTaskListData(allOverdueTasks),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setAllOverdueTaskList({ ...allOverdueTaskList, isLoading: false });
    }
  };

  const updateTask = async (params) => {
    const { row } = params;
    setIsLoading(true);
    const task = await getTask(row.id);
    dispatch(getTaskForm(task));
    setIsLoading(false);
    setOpenTaskDialog(true);
  };

  const handleClose = () => {};

  const handleTaskCompleted = async (params) => {
    setLoadingRowId(params.row.id);
    const { row } = params;
    onSave(
      taskComplete,
      row.completed,
      postMarkInComplete,
      postMarkComplete,
      row.id,
      '',
      fetchAllOverdueTasks,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setTaskCompletedLoading,
      taskCompletedLoading
    );
  };

  const pageSizeChange = pageSizeChangeHandler(setAllOverdueTaskList);

  return (
    <>
      <MDBox>
        <Card>
          {getDataGridHeading('All Overdue Tasks of System and Supervisor')}
          <DeltaDataGrid
            listData={allOverdueTaskList}
            columns={taskColumns(
              updateTask,
              handleTaskCompleted,
              taskCompletedLoading,
              loadingRowId
            )}
            setListData={pageSizeChange}
          />
        </Card>
      </MDBox>
      <TaskDialog
        open={openTaskDialog}
        setOpen={setOpenTaskDialog}
        fetchTasks={fetchAllOverdueTasks}
        isLoading={isLoading}
      />
      <NotificationPopup />
    </>
  );
};

export default AllOverdueTaskList;
