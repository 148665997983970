import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import {
  getDataGridHeading,
  useResetSnackbar,
  useMembersState,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import { intialPageSize } from 'constants';
import { getUsersList } from 'layouts/users/services/userApis';
import { useDispatch } from 'react-redux';
import { getLocationData } from 'layouts/inventory/location/services/LocationApi';
import { availableUsers } from 'layouts/groups/store/availableUsersSlice';
import { TypographyLink } from 'components/Ui/styled';
import MembersDialog from 'layouts/groups/list/MembersDialog';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const UserLocationList = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { loading, membersDialog, handleMembers, setMembersDialog, listName } =
    useMembersState();
  const [userLocationList, setUserLocationList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchUsersList();
    getAvailableLocations();
  }, []);

  const getAvailableLocations = async () => {
    const listOfLocationsAvailable = await getLocationData();
    dispatch(availableUsers(listOfLocationsAvailable?.items));
  };

  const fetchUsersList = async () => {
    setUserLocationList({ ...userLocationList, isLoading: true });
    const userList = await getUsersList();
    setUserLocationList({ ...userLocationList, isLoading: false });
    setUserLocationList((previous) => ({
      ...previous,
      rows: userList?.map((item, index) => ({
        ...item,
        id: index,
        username: item.username,
        location: item.assignedLocations.length,
        assignedLocations: item.assignedLocations,
        locationIdList: item.assignedLocations?.map((item2, index2) => ({
          id: item2.id,
          locationId: item2.val,
        })),
      })),
    }));
  };

  const handleAssignedLocations = handleMembers(
    'Assigned Locations',
    'Locations'
  );

  const columns = [
    { headerName: 'User Name', field: 'username', flex: 1 },
    {
      headerName: 'Locations',
      field: 'location',
      renderCell: (params) => {
        return (
          <TypographyLink
            variant="h6"
            onClick={() => handleAssignedLocations(params)}
          >
            {params.row.location}
          </TypographyLink>
        );
      },
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const pageSizeChange = pageSizeChangeHandler(setUserLocationList);

  return (
    <>
      <Card>
        {getDataGridHeading('Assign locations to users')}
        <DeltaDataGrid
          listData={userLocationList}
          columns={columns}
          setListData={pageSizeChange}
        />
      </Card>
      <MembersDialog
        setMembersDialog={setMembersDialog}
        membersDialog={membersDialog}
        fetchGroups={fetchUsersList}
        loading={loading}
        listName={listName}
      />
    </>
  );
};

export default UserLocationList;
